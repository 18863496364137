import React from "react"
import { Content, Header, Layout } from "../components"
import { Helmet } from "react-helmet"
import config from "../../config/SiteConfig"
import { Link } from "gatsby"

export default () => {
  return (
    <Layout>
      <Helmet title={`404 not found | ${config.siteTitle}`} />
      <Content>
        <h1>404: NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Content>
    </Layout>
  )
}
